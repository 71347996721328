import { useEffect, useState } from "react";
import { ethers } from "ethers";

import { getSecondsTimestamp } from "../../../lib/DateTime";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
import Main from "../../Main/Main.js";

import PageMessage from "../../PageMessage/PageMessage";
import Button from "../../Button/Button";
import ModalMessage from "../../ModalMessage/ModalMessage";
import WalletDropdown from "../../WalletDropdown/WalletDropdown";

import PageHeader from "../../PageHeader/PageHeader";
import MarketplacePropertyItem from "../MarketplacePropertyItem/MarketplacePropertyItem";
import PageHeaderNavigation from "../PageHeaderNavigation/PageHeaderNavigation";

import { useMarketplace } from "../../../hooks/useMarketplace";

import styles from "./AvailableProperties.module.css";

import { ReactComponent as CheckGreenIcon } from "../../../assets/icons/check-green-icon.svg";
import { ReactComponent as VerificationIcon } from "../../../assets/icons/verification-icon.svg";
import { ReactComponent as ThankYouIcon } from "../../../assets/icons/thank-you-icon.svg";

const AvailableProperties = ({
  usdcDecimals,
  web3Handler,
  account,
  marketplace,
  user,
  onClickVerify,
  signOut,
  isLoading,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleOpenModal1 = () => setModalOpen1(true);
  const handleCloseModal1 = () => setModalOpen1(false);

  const { properties, loading } = useMarketplace({ usdcDecimals, marketplace });

  const filteredAvailableProperties = properties.filter(
    (item) => item.pledgingDueTime.toNumber() > getSecondsTimestamp(Date.now())
  );

  const getOwnedValue = (owned) => (account ? owned : "N/A");

  const handleClickVerify = () => {
    handleCloseModal();
    handleOpenModal1();
    onClickVerify();
  };

  const isReview = user.kycAction === "review";
  const isReject = user.kycAction === "reject";
  const isUnblock = user.kycAction === "unblock";

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
          isLoading={isLoading}
        />
      }
      leftNavBar={<LeftNavBar selected="marketplace" />}
      main={
        <Main>
          <PageHeader title="Closin Marketplace">
            <PageHeaderNavigation tabSelected="available" />
          </PageHeader>
          {loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <>
              {!filteredAvailableProperties?.length ? (
                // <span className={styles.Loading}>No Properties</span>
                // EARLYBIRD
                user?.verified ? (
                  <PageMessage
                    icon={<ThankYouIcon />}
                    title={
                      <>
                        You have been verified and are on the whitelist <br />
                        for early access to invest in our first property!
                      </>
                    }
                  />
                ) : (
                  <PageMessage
                    icon={<VerificationIcon />}
                    title={
                      isReview ? (
                        <>
                          Your verification requires manual review from the
                          Closin team. This typically takes less than 24 hours.
                          You will be contacted from help@closin.com with
                          further information.
                        </>
                      ) : isReject ? (
                        <>
                          Your verification has failed for the reason: <br />"
                          {user?.kycFlags}"
                          <br />
                          Please reach out to help@closin.com
                        </>
                      ) : isUnblock ? (
                        <>
                          Your verification was reviewed. <br />
                          Please continue with your verification process.
                        </>
                      ) : (
                        <>
                          Our first property will launch soon with limited
                          ownership available. <br />
                          {/* Whitelist now by verifying your identity. */}
                          Whitelist now to avoid missing out.
                        </>
                      )
                    }
                    button={
                      !isReview && !isReject ? (
                        <Button large onClick={handleOpenModal}>
                          {/* Verify Identity */}
                          Whitelist
                        </Button>
                      ) : null
                    }
                  />
                )
              ) : (
                <div className={styles.AvailableProperties}>
                  {filteredAvailableProperties.map((property) => (
                    <MarketplacePropertyItem
                      key={property.id}
                      id={property.id}
                      name={ethers.utils.parseBytes32String(property.name)}
                      threshold={property.pledgingThreshold}
                      value={200000}
                      totalSupply={property.tokensAvailable}
                      minted={property.vouchersCount}
                      nftPrice={property.tokenPriceFormatted}
                      projectedIrr={18.64}
                      cashPayout={6.44}
                      pledgingDueTime={property.pledgingDueTime.toNumber()}
                      owned={getOwnedValue(property.vouchersOwned.toNumber())}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          {modalOpen && (
            <ModalMessage
              onClickClose={handleCloseModal}
              message={
                <WalletDropdown
                  account={account}
                  user={user}
                  web3Handler={web3Handler}
                  onClickVerify={handleClickVerify}
                  signOut={signOut}
                  isEarlyBird={true} // EARLYBIRD
                  handleClose={handleCloseModal} // EARLYBIRD
                />
              }
            />
          )}
          {modalOpen1 && (
            <ModalMessage
              icon={<VerificationIcon />}
              title={user.verified ? <>Congratulations!</> : <>Verification</>}
              showCloseCross={user.verified}
              onClickClose={handleCloseModal1}
              button={
                (user.verified || isReview || isReject) && (
                  <Button large onClick={handleCloseModal1}>
                    Close
                  </Button>
                )
              }
              message={
                user.verified ? (
                  <>You have been verfified.</>
                ) : isReview ? (
                  <>
                    Your verification requires manual approval from the Closin
                    team. This typically takes less than 24 hours. You will be
                    contacted from help@closin.com with further information.
                  </>
                ) : isReject ? (
                  <>
                    Your verification has failed for the reason:{" "}
                    <strong>"{user.kycFlags}"</strong>
                    <br />
                    <br /> Please reach out to help@closin.com
                  </>
                ) : (
                  <>
                    Please wait while we verify your details.
                    <br />
                    <br />
                    Verifying...
                  </>
                )
              }
            />
          )}
        </Main>
      }
    />
  );
};

export default AvailableProperties;
